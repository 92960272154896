import { useCompanyMutations } from "@app/entities/company/mutations";
import { useCompany } from "@app/providers/CompanyContextProvider";
import Form from "antd/lib/form";
import { useEffect } from "react";

export const useNotificationsLoader = () => {
  const { selectedCompany } = useCompany();
  const [notificationsForm] = Form.useForm();

  useEffect(() => {
    if (!selectedCompany) return;
    const { notificationsEmail, lowBalanceThreshold, notifyStaleReports } =
      selectedCompany;
    notificationsForm.setFieldsValue({
      notificationsEmail,
      lowBalanceThreshold: (lowBalanceThreshold ?? 0) / 100,
      notifyStaleReports,
    });
  }, [selectedCompany]);

  const { updateNotifications, isMutationPending } = useCompanyMutations();

  const handleUpdateNotifications = () => {
    const { email, lowBalanceThreshold, notifyStaleReports } =
      notificationsForm.getFieldsValue();
    const notificationsData = {
      email,
      lowBalanceThreshold: lowBalanceThreshold * 100,
      notifyStaleReports,
    };
    updateNotifications(notificationsData);
  };

  const isLoading = isMutationPending;

  return {
    notificationsForm,
    handleUpdateNotifications,
    isLoading,
  };
};
